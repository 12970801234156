import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { ContentLayout } from "../../components/content-blocks/content-layout";
import { Seo } from "../../components/seo";
import {
  EntryQueryInterface,
  EntriesQueryInterface,
} from "../../types/entries";
import { LayoutDefault } from "../../layouts/default";
import { GridContainer } from "../../components/grid/content-blocks/container";
import { GridRow } from "../../components/grid/content-blocks/row";
import { EntryLists } from "../../components/entry-lists";

const RelatedArticles = styled.section``;

export const ArticlePage = (
  props: EntryQueryInterface & EntriesQueryInterface
) => {
  const { entry, draft } = props?.data?.craft;
  const entryData = draft ? draft : entry;
  const { id, title } = entryData;

  const entries = props?.data?.craft?.entries?.filter((item) => item.id !== id);

  return (
    <LayoutDefault>
      <Seo title={title} entry={entry} />
      <ContentLayout {...entryData} />
      {entries && entries?.length ? (
        <RelatedArticles>
          <GridContainer>
            <GridRow>
              <hr />
              <h4>More Articles</h4>
              <EntryLists entries={entries} />
            </GridRow>
          </GridContainer>
        </RelatedArticles>
      ) : null}
    </LayoutDefault>
  );
};

export const query = graphql`
  query EntryArticlePageQuery(
    $slug: [String]
    $sectionHandle: [String]
    $searchString: String
    $status: [String]
    $showDrafts: Boolean!
  ) {
    craft {
      entry(
        slug: $slug
        section: $sectionHandle
        drafts: false
        status: $status
      ) {
        ...entryArticleStories
      }
      draft: entry(
        slug: $slug
        section: $sectionHandle
        drafts: $showDrafts
        status: $status
        orderBy: "dateUpdated"
        inReverse: true
      ) @include(if: $showDrafts) {
        ...entryArticleStories
      }
      entries(
        section: $sectionHandle
        search: $searchString
        orderBy: "score"
        limit: 6
      ) {
        ...entryArticleStories
      }
    }
  }
`;

export default ArticlePage;
